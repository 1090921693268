/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start();
import "html5shiv";

document.addEventListener("DOMContentLoaded", function () {
  bindContactForm("new_contact");

  //mobile menu
  const menu = document.getElementById("menu");

  $("#mobile-menu").click(function () {
    event.preventDefault();

    menu.classList.toggle("active");
    if (menu.classList.contains("active")) {
      this.title = "Close Menu";
      menu.focus();
    } else {
      this.title = "Open Menu";
    }
  });

  //Smooth scroll to contact form
  document.querySelectorAll(".contact, #contact").forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault()
      const contact_us = document.getElementById("contact-us")
      contact_us.scrollIntoView({
        behavior: 'smooth',
        block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
      })
    })
  });

    //Smooth scroll to description
    document.querySelectorAll("#intro .learn-more").forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault()
        const NAVBAR_HEIGHT = document.getElementsByTagName('header')[0].offsetHeight;
        const OFFSET = NAVBAR_HEIGHT + 20;

        const BODY    = document.body.getBoundingClientRect().top;
        const SECTION = document.getElementById('description').getBoundingClientRect().top;

        const SECTION_POS = SECTION - BODY
        const OFFSET_POS  = SECTION_POS - OFFSET

        window.scrollTo({
          top: OFFSET_POS,
          behavior: 'smooth'
        });
      })
    });

  //Smooth scroll to about us
  document.querySelectorAll(".about").forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault()
      const about_us = document.getElementById("why-us-index")
      about_us.scrollIntoView({
        behavior: 'smooth',
        block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
      })
    })
  });

  // Change the color of the navbar when scrolling
  function checkFixedHeader() {
    const scroll = window.scrollY;
    const header = document.querySelector("header");
    if (scroll >= 5) {
      header.classList.add("fixed");
    } else {
      header.classList.remove("fixed");
    }
  }

  $(window).scroll(checkFixedHeader);
  checkFixedHeader();
});

// MAPS section
var map;
var marker;
global.initMap = function () {
  var myLatlng = { lat: 40.046991, lng: -75.1949719 };
  var center = { lat: 40.046991, lng: -75.1971445 };
  var tabletCenter = { lat: 40.046991, lng: -75.1970755 };
  var mobileCenter = { lat: 40.0480719, lng: -75.1949719 };
  map = new google.maps.Map(document.getElementById("map"), {
    zoom: 17,
    disableDefaultUI: true,
    styles: [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#ebe3cd",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#523735",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f1e6",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#c9b2a6",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#dcd2be",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#ae9e90",
          },
        ],
      },
      {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffcc00",
          },
        ],
      },
      {
        featureType: "landscape.natural",
        elementType: "geometry",
        stylers: [
          {
            color: "#dfd2ae",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#dfd2ae",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#93817c",
          },
        ],
      },
      {
        featureType: "poi.business",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#1bb076",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#447530",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f1e6",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            color: "#fdfcf8",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#f8c967",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#f70a7c",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#e9bc62",
          },
        ],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry",
        stylers: [
          {
            color: "#e98d58",
          },
        ],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#db8555",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#806b63",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#dfd2ae",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8f7d77",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#ebe3cd",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#dfd2ae",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#d3f2f1",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#92998d",
          },
        ],
      },
    ],
  });
  map.setOptions({
    draggable: false,
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  });
  var pin = {
    path: "M25.5,0C11.4,0,0,11.5,0,25.7c0,17.6,22.8,43.4,23.8,44.5c0.9,1,2.5,1,3.4,0c1-1.1,23.8-26.9,23.8-44.5 C51,11.5,39.6,0,25.5,0z M26,39c-7.2,0-13-5.8-13-13s5.8-13,13-13s13,5.8,13,13S33.2,39,26,39z",
    fillColor: "black",
    fillOpacity: 1,
    scale: 1,
    size: new google.maps.Size(51, 71),
    origin: new google.maps.Point(0, 0),
    // The anchor for this image is the base of the flagpole at (0, 32).
    anchor: new google.maps.Point(15, 60),
  };
  marker = new google.maps.Marker({
    position: myLatlng,
    icon: pin,
    map: map,
  });

  const setCenter = () => {
    if (window.innerWidth <= 600) {
      map.setCenter(mobileCenter);
    } else if (window.innerWidth <= 768) {
      map.setCenter(tabletCenter);
    } else {
      map.setCenter(center);
    }
  };

  google.maps.event.addDomListener(window, "load", function () {
    setCenter();
  });
  google.maps.event.addDomListener(window, "resize", function () {
    setCenter();
  });
};

global.renderValue = function (value) {
  document.getElementById("coverage__value").innerHTML = value;
};

// insert an error div after the given input
function addErrorField(inputId, message) {
  const input = document.getElementById(inputId);
  const errorDiv = document.createElement("div");
  errorDiv.innerText = message;
  errorDiv.classList.add("input-error");
  input.insertAdjacentElement("afterend", errorDiv);
  input.parentElement.classList.add("has-error");
}

function bindContactForm(formId) {
  const contactForm = document.getElementById(formId);
  if (!contactForm) return;

  contactForm.addEventListener("ajax:success", function (event) {
    const formEmail = contactForm.querySelector("#contact_email").value;

    if (event.detail[0].redirect_to) {
      window.location = event.detail[0].redirect_to;
    } else {
      $("#new_contact").slideUp(200, function () {
        const msg =
          '<div class="contact-img" id="contact-success-wrapper"><img src="/assets/contact.gif" /></div><h2 class="form-success">Thank you! <br>We will get back to you soon ;-) </h2>';
        document.getElementById("new_contact").innerHTML = msg;
        $("#new_contact").slideDown(200);
      });
    }
  });

  contactForm.addEventListener("ajax:error", function (e) {
    const [data, status, xhr] = e.detail;

    // clear error state
    contactForm.querySelectorAll(".input-error").forEach((e) => e.remove());
    contactForm
      .querySelectorAll(".has-error")
      .forEach((e) => e.classList.remove("has-error"));

    // process new errors
    for (let error in data) {
      switch (error) {
        case "email":
          addErrorField("contact_email", `Email ${data[error]}`);
          break;
        case "name":
          addErrorField("contact_name", `Your name ${data[error]}`);
          break;
        case "version":
          addErrorField("contact_version", `Version ${data[error]}`);
          break;
        case "message":
          addErrorField("contact_message", `Message ${data[error]}`);
          break;
      }
    }
  });
}
